body {
  font-family: CalibriWeb, Arial, sans-serif;
  background: #D9E3EC;
  width: 100%;
  overflow-x: auto;
}

button {
  font-family: inherit;
}

a {
  color: currentColor;
  text-decoration: none;
}
