@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=516a4e0e-7e9a-49d4-9e4d-2a06a1e57306&fontids=6018018,6018024,6018040,6018047,6018049,6053091");

@font-face{
  font-family: "CalibriWeb";
  src: url("./fonts/calibri.eot?#iefix");
  src: url("./fonts/calibri.eot?#iefix") format("eot"),
       url("./fonts/calibri.woff2") format("woff2"),
       url("./fonts/calibri.woff") format("woff"),
       url("./fonts/calibri.ttf") format("truetype");
  font-display: fallback;
}

@font-face{
  font-family: "CalibriWeb";
  font-weight: bold;
  src: url("./fonts/calibri-bold.eot?#iefix");
  src: url("./fonts/calibri-bold.eot?#iefix") format("eot"),
       url("./fonts/calibri-bold.woff2") format("woff2"),
       url("./fonts/calibri-bold.woff") format("woff"),
       url("./fonts/calibri-bold.ttf") format("truetype");
  font-display: fallback;
}

@font-face{
  font-family: "CalibriWeb";
  font-style: italic;
  src: url("./fonts/calibri-italic.eot?#iefix");
  src: url("./fonts/calibri-italic.eot?#iefix") format("eot"),
       url("./fonts/calibri-italic.woff2") format("woff2"),
       url("./fonts/calibri-italic.woff") format("woff"),
       url("./fonts/calibri-italic.ttf") format("truetype");
  font-display: fallback;
}

@font-face{
  font-family: "CalibriWeb";
  font-style: italic;
  font-weight: bold;
  src: url("./fonts/calibri-bold-italic.eot?#iefix");
  src: url("./fonts/calibri-bold-italic.eot?#iefix") format("eot"),
       url("./fonts/calibri-bold-italic.woff2") format("woff2"),
       url("./fonts/calibri-bold-italic.woff") format("woff"),
       url("./fonts/calibri-bold-italic.ttf") format("truetype");
  font-display: fallback;
}

@font-face{
  font-family: "CalibriWeb Light";
  src: url("./fonts/calibri-light.eot?#iefix");
  src: url("./fonts/calibri-light.eot?#iefix") format("eot"),
       url("./fonts/calibri-light.woff2") format("woff2"),
       url("./fonts/calibri-light.woff") format("woff"),
       url("./fonts/calibri-light.ttf") format("truetype");
  font-display: fallback;
}

@font-face{
  font-family: "CalibriWeb Light";
  font-style: italic;
  src: url("./fonts/calibri-light-italic.eot?#iefix");
  src: url("./fonts/calibri-light-italic.eot?#iefix") format("eot"),
       url("./fonts/calibri-light-italic.woff2") format("woff2"),
       url("./fonts/calibri-light-italic.woff") format("woff"),
       url("./fonts/calibri-light-italic.ttf") format("truetype");
  font-display: fallback;
}
